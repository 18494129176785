import { getIn } from "formik";
import React from "react";
import { Checkbox, CheckboxProps, Dropdown, DropdownProps, Form, Grid, InputOnChangeData, Message, Radio, Segment, TextArea, TextAreaProps } from "semantic-ui-react";
import { AppLabel, ILabelProps } from "./AppLabel";
import DatePicker from "react-datepicker";

export interface IOption {
    value: string;
    text: string;
}

interface IFieldProps extends ILabelProps {
    name: string;
    disabled?: boolean;
    value: any;
    onChanged: (val: any) => void;
    error: string;
}

interface IOptionProps extends IFieldProps {
    options: IOption[];
    wildcard?: boolean;
}

export function getErrorText(formik: any, name: string): any {
    return getIn(formik.touched, name) && getIn(formik.errors, name) ? getIn(formik.errors, name) : null;
};

export function AppTextbox(props: IFieldProps){
    const handleChange = (e: any, data: InputOnChangeData) => {
        if (data.value !== props.value) {
            props.onChanged(data.value);
        }
    }

    return (
        <Form.Field>
            <AppLabel label={props.label} subLabel={props.subLabel} subLabelLink={props.subLabelLink} required={props.required} />
            <Form.Input
                name={props.name}
                onChange={handleChange}
                value={props.value}
                disabled={props.disabled}
            />
            <Message error visible={props.error ? true : false}>
                {props.error}
            </Message>
        </Form.Field>
    )
}

export function AppTextArea(props: IFieldProps){
  const handleChange = (e: any, data: TextAreaProps) => {
      if (data.value !== props.value) {
          props.onChanged(data.value);
      }
  }

  return (
      <Form.Field>
          <AppLabel label={props.label} subLabel={props.subLabel} subLabelLink={props.subLabelLink} required={props.required} />
          <TextArea 
            name={props.name}
            onChange={handleChange}
            value={props.value}
            disabled={props.disabled}
          />
          <Message error visible={props.error ? true : false}>
              {props.error}
          </Message>
      </Form.Field>
  )
}

export function SimpleTextBox(props: { name: string, label: string, subLabel?: string, formik: any, disabled?: boolean, required?:boolean }){
    return (
        <AppTextbox
            name={props.name}
            label={props.label}
            subLabel={props.subLabel}
            disabled={props.disabled}
            value={props.formik.values[props.name]}
            onChanged={(value) => props.formik.setFieldValue(props.name, value)}
            error={getErrorText(props.formik, props.name)}
            required={props.required}
        />
    )
}

export interface IDateProps extends IFieldProps {
    minDate?: Date
}
export function AppDate(props: IDateProps){
    const handleChange = (d: Date) => {
        const iso = d.toISOString();
        if (props.value !== iso) {
            props.onChanged(iso);
        }
    }

    return (
        <Form.Field>
            <AppLabel label={props.label} subLabel="" subLabelLink={props.subLabelLink} required={props.required} />
            <DatePicker
                selected={props.value ? new Date(props.value) : null}
                onChange={handleChange}
                minDate={props.minDate}
                // maxDate={props.maxDate}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                strictParsing
                dateFormat="M/d/yyyy"
            />
            <Message error visible={props.error ? true : false}>
                {props.error}
            </Message>
        </Form.Field>
    );
};

export function AppNumberField(props: IFieldProps) {

    const handleChange = (e: any, data: InputOnChangeData) => {
        try {
            const newVal = parseInt(data.value, 10);
            if (isNaN(newVal) || newVal < 0) {
                props.onChanged(0);
            } else {
                if (props.value !== newVal) {
                    props.onChanged(newVal);
                }
            }
        } catch (error) {
            props.onChanged(0);
        }
    }

    return (
        <Form.Input
            label={props.label}
            required={props.required}
            name={props.name}
            onChange={handleChange}
            value={props.value}
            disabled={props.disabled}
            error={props.error && { content: props.error }}
        />
    );
}


export function AppDropdown(props: IOptionProps) {

    const handleChange = (e: any, data: DropdownProps) => {
        props.onChanged(data.value);
    }

    return (
        <Form.Field>
            <AppLabel
                label={props.label}
                subLabel={props.subLabel}
                subLabelLink={props.subLabelLink}
                required={props.required}
            />
            <Message error visible={props.error ? true : false}>
                {props.error}
            </Message>
            <Dropdown
                search
                selection
                value={props.value}
                options={props.options}
                onChange={handleChange}
                disabled={props.disabled}
            />
        </Form.Field>
    )
}


export function AppRadioGroup(props: IOptionProps) {

    const handleClick = (newVal: string) => {
        if (props.disabled) {
            return;
        }
        props.onChanged((props.value === newVal) ? "" : newVal);
    }

    const renderOption = (option: IOption) => {
        const key = `${props.name}-${option.value}`;
        return (
            <Form.Field key={key}>
                <Radio
                    label={option.text}
                    name={props.name}
                    value={props.value}
                    checked={props.value === option.value}
                    onClick={() => handleClick(option.value)}
                    disabled={props.disabled}
                />
            </Form.Field>
        );
    }

    return (
        <Segment>
            <AppLabel
                label={props.label}
                subLabel={props.subLabel}
                subLabelLink={props.subLabelLink}
                required={props.required} />
            <Message error visible={props.error ? true : false}>
                {props.error}
            </Message>
            {props.options.map(renderOption)}
        </Segment>);
}


export function AppCheckboxGroup(props: IOptionProps) {

    const wildcard = props.value.includes("*");

    const handleClick = (e: any, data: CheckboxProps) => {
        if (props.disabled || wildcard) {
            return;
        }
        const option = data.value.toString();

        const copy: string[] = [...props.value];

        if (data.checked) {
            if (!copy.includes(option)) {
                props.onChanged([...copy, option])
            }
        } else {
            const index = copy.indexOf(option);
            if (index > -1) {
                copy.splice(index, 1);
                props.onChanged(copy);
            }
        }
    }

    const handleClickWildcard = (e: any, data: CheckboxProps) => {
        if (props.disabled) {
            return;
        }

        if (data.checked) {
            props.onChanged(["*"])
        } else {
            props.onChanged([])
        }
    }

    const renderOption = (option: IOption) => {
        const key = `${props.name}-${option.value}`;
        return (
            <Grid.Column width={8} key={key}>
                <Form.Field>
                    <Checkbox
                        label={option.text}
                        name={props.name}
                        value={option.value}
                        checked={props.value.includes(option.value)}
                        onClick={handleClick}
                        disabled={props.disabled || wildcard}
                    />
                </Form.Field>
            </Grid.Column>
        )
    }

    return (<Segment>
        <Grid stackable>
            <Grid.Column width={16} key={`${props.name}-label`}>
                <AppLabel label={props.label} subLabel={props.subLabel} subLabelLink={props.subLabelLink} required={props.required} />
                <Message error visible={props.error ? true : false}>
                    {props.error}
                </Message>
            </Grid.Column>

            {props.wildcard && <Grid.Column width={8} key={`${props.name}-wildcard`}>
                <Checkbox label="N/A" disabled={props.disabled} onClick={handleClickWildcard} checked={props.value.includes("*")} />
            </Grid.Column>}

            {props.options.map(renderOption)}
        </Grid>
    </Segment>);
}