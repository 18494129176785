import { useContext, useEffect, useState } from "react";
import { QueryParams } from "../shared/api-interfaces";
import AppContext from "../contexts/AppContext";

export function usePreview(query: QueryParams){
  const context = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const downloadFile = async (query: QueryParams, fileName: string): Promise<void> => {
    setLoading(true);

    try {
      const blob = await context.api.getPreview(query);

      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setError(null);

    if (query) {
      downloadFile(query, "export.csv");
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return {
    error,
    loading,
  };
};
