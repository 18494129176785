import { useContext, useEffect, useState } from "react";
import { ExportStatus, QueryParams } from "../shared/api-interfaces";
import AppContext from "../contexts/AppContext";

const defaultResult: ExportStatus = {
  pk: "",
  sk: "",
  exportStatus: "" as any,
  statusMessage: "",
}

export function useExport(query: QueryParams) {
  const context = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState(defaultResult);

  const fetchData = async () => {
    setLoading(true);
    
    setResult({
      pk: "",
      sk: "",
      exportStatus: "Requesting",
      statusMessage: "",
    });

    try {
      const exportStatus = await context.api.postExport(query);
      setResult(exportStatus);
      pollResults(exportStatus.pk, exportStatus.sk);
    } catch (error) {
      setResult({
        pk: "",
        sk: "",
        exportStatus: "Error",
        statusMessage: error.message,
      });
      setLoading(false)
    }
  }

  const pollResults = (pk: string, sk: string) => {
    const intervalId: any = setInterval(async () => {
      try {
        const response = await context.api.getExportStatus({
          jurisdiction: query.jurisdiction,
          pk: pk,
          sk: sk,
        });
        setResult(response)
        if (response.exportStatus === "Complete" || response.exportStatus === "Error") {
          return endPolling(intervalId);
        }
      } catch (error) {
        setResult({
          pk: "",
          sk: "",
          exportStatus: "Error",
          statusMessage: error.message,
        });
        return endPolling(intervalId);
      }
    }, 3000)
  }

  const endPolling = (intervalId: any) => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    setLoading(false)
  }

  useEffect(() => {
    if (query) {
      fetchData();
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return {
    loading,
    result,
  };
};
