import React, { useContext, useState } from 'react'
import { Button, Header, Icon, Message, Segment, Table } from 'semantic-ui-react'
import { EligibilityRule, QueryParams } from "../../shared/api-interfaces";
import AppContext from '../../contexts/AppContext'
import { useRules } from "../../hooks/useRules";
import { defaultQueryParams } from "../../model/defaults";
import { AddRuleForm } from "./AddRuleForm";


interface IProps {
  query: QueryParams,
  showCreateButton: boolean,
  replaceQuery: (query: QueryParams) => void;
}

function Rules(props: IProps) {
  const ctx = useContext(AppContext);
  const [error, setError] = useState("");
  const [refresh, setRefresh] = useState(new Date().getTime());
  const [activeRule, setActiveRule] = useState<EligibilityRule>(null);

  const rules = useRules({ jurisdiction: props.query.jurisdiction, refresh })

  const deleteItem = async (rule: EligibilityRule) => {
    try {
      setError("");
      await ctx.api.deleteRule(rule.jurisdiction, rule.name);
      setRefresh(new Date().getTime())
    } catch (error) {
      setError(error.message);
    }
  }

  const createRuleClicked = () => {
    setError("");
    setActiveRule({
      jurisdiction: props.query.jurisdiction,
      query: props.query,
      name: "",
      priority: 1,
      startDate: "",
      endDate: "",
    })
  }

  const loadRule = (rule: EligibilityRule) => {
    setError("");
    setActiveRule(rule);
    props.replaceQuery(rule.query);
  }

  const onSaved = ()=> {
    props.replaceQuery(defaultQueryParams(props.query.jurisdiction))
    setActiveRule(null);
    setRefresh(new Date().getTime());
  }

  const activeError = error || rules.error;

  return (
    <Segment loading={rules.loading}>
      <Header as="h3">
        Eligibility Rules
      </Header>
      {!activeRule && props.showCreateButton && <Button onClick={() => createRuleClicked()}>Create Rule</Button>}
      {activeRule && <AddRuleForm rule={activeRule} query={props.query} onClose={() => setActiveRule(null)} onSave={() => onSaved()} />}
      <Table sortable stackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Priority</Table.HeaderCell>
            <Table.HeaderCell>Start</Table.HeaderCell>
            <Table.HeaderCell>End</Table.HeaderCell>
            <Table.HeaderCell>Updated By</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rules.result.map((rule: EligibilityRule) => (
            <Table.Row key={rule.name}>
              <Table.Cell>
                {!activeRule && <Button icon color={"blue"} onClick={() => loadRule(rule)}> <Icon disabled name='edit' /></Button>}
              </Table.Cell>
              <Table.Cell>{rule.name}</Table.Cell>
              <Table.Cell>{rule.priority}</Table.Cell>
              <Table.Cell>{new Date(rule.startDate).toLocaleDateString()}</Table.Cell>
              <Table.Cell>{new Date(rule.endDate).toLocaleDateString()}</Table.Cell>
              <Table.Cell>{rule.updatedBy}</Table.Cell>
              <Table.Cell>
                {!activeRule && <Button icon color={"red"} onClick={() => deleteItem(rule)}> <Icon disabled name='delete' /></Button>}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {activeError && <Message error>
        <p>{activeError}</p>
      </Message>}

    </Segment>
  )
}

export default Rules