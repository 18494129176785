import React, { useContext, useState } from "react";
import { Segment } from "semantic-ui-react";
import AppContext from "../../contexts/AppContext";
import { AppCheckboxGroup, AppDropdown, AppNumberField, AppTextArea, getErrorText, IOption, SimpleTextBox } from "./AppFormInputs";

interface IQuestionProps {
    formik: any,
    disabled?: boolean,
    getErrorText: (name: string) => string;
}

const formatOptions = (options: IOption[], title?: boolean): IOption[] => {
  return options.map(x => ({ text: x.text.replace(/ *\([^)]*\) */g, " "), value: x.value }));
}

export function JurisdictionQuestion(props: IQuestionProps) {

  const name = "jurisdiction";
  const ctx = useContext(AppContext)
  const config = ctx.getQuestion(name);
  const [options] = useState(config.options.filter(x => ctx.groups.includes(x.value)))

  return (
    <Segment>
      <AppDropdown
        name={name}
        label="Jurisdiction"
        required={true}
        options={options}
        disabled={props.disabled}
        value={props.formik.values[name]}
        onChanged={(value) => { props.formik.setFieldValue(name, value) }}
        error={props.getErrorText(name)}
      />
    </Segment>
  )
}

export function AgeQuestion(props: IQuestionProps){

  const name = "age"
  const ctx = useContext(AppContext)
  const config = ctx.getQuestion(name);
  const [options] = useState(formatOptions(config.options))

  return (
    <AppCheckboxGroup
      name={name}
      label="Age"
      required={true}
      options={options}
      disabled={props.disabled}
      value={props.formik.values[name]}
      onChanged={(value) => props.formik.setFieldValue(name, value)}
      error={props.getErrorText(name)}
      wildcard={false}
    />
  )
}

export function HealthcareQuestion(props: IQuestionProps){
  const name = "healthcare"
  const ctx = useContext(AppContext)
  const config = ctx.getQuestion(name);
  const [options] = useState(formatOptions(config.options,true));

  return (
    <AppCheckboxGroup
      name={name}
      label="Healthcare"
      options={options}
      disabled={props.disabled}
      value={props.formik.values[name]}
      onChanged={(value) => props.formik.setFieldValue(name, value)}
      error={props.getErrorText(name)}
      wildcard={true}
    />
  )
}

export function LivingSituationQuestion(props: IQuestionProps) {
  const name = "livingSituation";
  const ctx = useContext(AppContext)
  const config = ctx.getQuestion(name);
  const [options] = useState(formatOptions(config.options,true));

  return (<AppCheckboxGroup
    name={name}
    label="Living Situation"
    options={options}
    disabled={props.disabled}
    value={props.formik.values[name]}
    onChanged={(value) => props.formik.setFieldValue(name, value)}
    error={props.getErrorText(name)}
    wildcard={true}
  />)
}

export function RuralQuestion(props: IQuestionProps) {
  const name = "rural";
  const ctx = useContext(AppContext)
  const config = ctx.getQuestion(name);
  const [options] = useState(formatOptions(config.options,true));

  return (<AppCheckboxGroup
    name={name}
    label="Rural"
    options={options}
    disabled={props.disabled}
    value={props.formik.values[name]}
    onChanged={(value) => props.formik.setFieldValue(name, value)}
    error={props.getErrorText(name)}
    wildcard={true}
  />)
}

export function FrontlineWorkerQuestion(props: IQuestionProps) {
  const name = "frontlineWorker";
  const ctx = useContext(AppContext)
  const config = ctx.getQuestion(name);
  const [options] = useState(formatOptions(config.options,true));

  return (<AppCheckboxGroup
    name={name}
    label="Frontline Worker"
    options={options}
    disabled={props.disabled}
    value={props.formik.values[name]}
    onChanged={(value) => props.formik.setFieldValue(name, value)}
    error={props.getErrorText(name)}
    wildcard={true}
  />)
}

export function EssentialWorkerQuestion(props: IQuestionProps) {
  const name = "essentialWorker";
  const ctx = useContext(AppContext)
  const config = ctx.getQuestion(name);
  const [options] = useState(formatOptions(config.options,true));


  return (<AppCheckboxGroup
    name={name}
    label="Essential Worker"
    options={options}
    disabled={props.disabled}
    value={props.formik.values[name]}
    onChanged={(value) => props.formik.setFieldValue(name, value)}
    error={props.getErrorText(name)}
    wildcard={true}
  />)
}

export function ConditionsQuestion (props: IQuestionProps) {
  const name = "conditions";
  const ctx = useContext(AppContext)
  const config = ctx.getQuestion(name);
  const [options] = useState(formatOptions(config.options,true));


  return (<AppCheckboxGroup
    name={name}
    label="Conditions"
    options={options}
    disabled={props.disabled}
    value={props.formik.values[name]}
    onChanged={(value) => props.formik.setFieldValue(name, value)}
    error={props.getErrorText(name)}
    wildcard={true}
  />)
}

export function ZipQuestion(props: IQuestionProps) {
  const name = "zip";

  return (
    <Segment>
      <AppTextArea
        name={name}
        label="Zip Code Begins With"
        subLabel="You may enter multiple zip codes, separated by a comma."
        value={props.formik.values[name]}
        onChanged={(value) => props.formik.setFieldValue(name, value)}
        error={getErrorText(props.formik, name)}
        disabled={props.disabled}
      />
    </Segment>
  )
}

export function PreviewLimitQuestion(props: IQuestionProps) {

  const name="previewLimit";

  return (
    <AppNumberField
    name={name}
      label="Preview Limit"
      required={true}
      disabled={props.disabled}
      value={props.formik.values[name]}
      onChanged={(value) => props.formik.setFieldValue(name, value)}
      error={props.getErrorText(name)}
    />
  )
}

export function ExportLimitQuestion(props: IQuestionProps) {

  const name = "exportLimit";

  return (
    <AppNumberField
      name={name}
      label="Export Limit"
      required={true}
      disabled={props.disabled}
      value={props.formik.values[name]}
      onChanged={(value) => props.formik.setFieldValue(name, value)}
      error={props.getErrorText(name)}
    />
  )
}

export function MonkeypoxExposureQuestion(props: IQuestionProps) {
  const name = "monkeypoxExposure";
  const ctx = useContext(AppContext)
  const config = ctx.getQuestion(name);
  const [options] = useState(formatOptions(config.options,true));

  return (<AppCheckboxGroup
    name={name}
    label="Mpox Exposure"
    options={options}
    disabled={props.disabled}
    value={props.formik.values[name]}
    onChanged={(value) => props.formik.setFieldValue(name, value)}
    error={props.getErrorText(name)}
    wildcard={true}
  />)
}

export function MonkeypoxGroupsQuestion(props: IQuestionProps) {
  const name = "monkeypoxGroup";
  const ctx = useContext(AppContext)
  const config = ctx.getQuestion(name);
  const [options] = useState(formatOptions(config.options,true));

  return (<AppCheckboxGroup
    name={name}
    label="Mpox High Risk Groups"
    options={options}
    disabled={props.disabled}
    value={props.formik.values[name]}
    onChanged={(value) => props.formik.setFieldValue(name, value)}
    error={props.getErrorText(name)}
    wildcard={true}
  />)
}

export function MonkeypoxSymptomsQuestion(props: IQuestionProps) {
  const name = "monkeypoxSymptoms";
  const ctx = useContext(AppContext)
  const config = ctx.getQuestion(name);
  const [options] = useState(formatOptions(config.options,true));

  return (<AppCheckboxGroup
    name={name}
    label="Mpox Symptoms"
    options={options}
    disabled={props.disabled}
    value={props.formik.values[name]}
    onChanged={(value) => props.formik.setFieldValue(name, value)}
    error={props.getErrorText(name)}
    wildcard={true}
  />)
}

export function MonkeypoxImmunocompromisingQuestion(props: IQuestionProps) {
  const name = "conditions";
  const ctx = useContext(AppContext)
  const config = ctx.getQuestion(name);
  const [options] = useState(formatOptions(config.options,true));

  return (<AppCheckboxGroup
    name={name}
    label="Mpox Immunocompromising Conditions"
    options={options}
    disabled={props.disabled}
    value={props.formik.values[name]}
    onChanged={(value) => props.formik.setFieldValue(name, value)}
    error={props.getErrorText(name)}
    wildcard={true}
  />)
}
