import { EligibilityRule, ExportStatus, GetStatusRequest, QueryParams } from "../shared/api-interfaces";
import ConfigurationService from "./ConfigurationService";
import { getLogger, Logger } from "./LoggingService";
import { Auth } from "aws-amplify";
export class ApiClient {
  logger: Logger;

  constructor(private cfg: ConfigurationService) {
    this.logger = getLogger("ApiClient");
  }

  private async request(endpoint: string, method: string, body?: any): Promise<Response> {
    const auth = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;

    return await fetch(this.cfg.apiUrl + endpoint, {
      method: method,
      body: body ? JSON.stringify(body) : undefined,
      headers: {
        Authorization: auth,
      },
    });
  }

  async post(endpoint: string, body: any): Promise<Response> {
    return this.request(endpoint, "POST", body);
  }

  async delete(endpoint: string, body: any): Promise<Response> {
    return this.request(endpoint, "DELETE", body);
  }

  async get(endpoint: string, params?: {[name:string]:string}): Promise<Response> {

    let url = endpoint;
    
    if(params){
      let query = Object.keys(params)
      .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&');
      url += "?"+query;
    }
    
    return this.request(url, "GET");
  }

  async getCount(query: QueryParams): Promise<number> {
    const response = await this.post("/private/count", query);
    this.logger.debug("getCount", { status: response.status });
    if (response.status === 200) {
      const result = await response.json();
      return result.count;
    } else {
      await this.handleError(response);
    }
  }

  async getPreview(query: QueryParams): Promise<Blob> {
    const response = await this.post("/private/preview", query);
    this.logger.debug("getCount", { status: response.status });
    if (response.status === 200) {
      return await response.blob();
    } else {
      await this.handleError(response);
    }
  }

  /**
   * Generate Export on backend. Returns export file name
   */
  async postExport(query: QueryParams): Promise<ExportStatus> {
    const response = await this.post("/private/export", query);
    this.logger.debug("postExport", { status: response.status });
    if (response.status === 200) {
      return await response.json();
    } else {
      await this.handleError(response);
    }
  }

  async getExportStatus(request: GetStatusRequest): Promise<ExportStatus> {
    const response = await this.post("/private/status", request);
    this.logger.debug("getExportStatus", { status: response.status });
    if (response.status === 200) {
      return await response.json();
    } else {
      await this.handleError(response);
    }
  }

  async handleError(response: Response): Promise<void> {    
    const body = await response.json();
    this.logger.debug("API Error", { status: response.status, body });

    if (response.status === 400) {
      const message = body.message
        ? body.message
        : Object.keys(body).map(key => `${key}: ${body[key].join(", ")}`).join("; ");

      throw new Error(message);
    } else {
      throw new Error(JSON.stringify(body));
    }
  }

  async getRules(jurisdiction: string): Promise<EligibilityRule[]>{
    const response = await this.get("/private/rules", {jurisdiction});
    this.logger.debug("getRules", { status: response.status });
    if (response.status === 200) {
      return await response.json();
    } else {
      await this.handleError(response);
    }
  }

  async saveRule(rule: EligibilityRule): Promise<void>{
    const response = await this.post("/private/rules", rule);
    this.logger.debug("saveRule", { status: response.status });
    if (response.status !== 200) {
      await this.handleError(response);
    }
  }

  async deleteRule(jurisdiction: string, name: string): Promise<void>{
    const response = await this.delete("/private/rules", {jurisdiction, name});
    this.logger.debug("deleteRule", { status: response.status });
    if (response.status !== 200) {
      await this.handleError(response);
    }
  }
}
