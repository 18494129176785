import { useContext, useEffect, useState } from "react";
import { EligibilityRule } from "../shared/api-interfaces";
import AppContext from "../contexts/AppContext";


export function useRules(props: {jurisdiction: string, refresh: number}) {
  const context = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState<EligibilityRule[]>([]);
  const [error, setError] = useState("");

  useEffect(() => {
    function fetchData() {
      setLoading(true);

      context.api
        .getRules(props.jurisdiction)
        .then((c) => setResult(c))
        .catch((e) => setError(e.message))
        .finally(() => setLoading(false));
    }

    setError(null);

    if (props.jurisdiction) {
      fetchData();
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.jurisdiction, props.refresh]);

  return {
    error,
    loading,
    result,
  };
};
