import moment from "moment";
import * as Yup from "yup";
import { GetLabelForLanguage, getQuestionForLanguage, LanguageId, QuestionId } from "./multi-language";

export interface EligibilityFormData {
    timestamp: string;
    preferredLanguage: LanguageId;
    jurisdiction: string;

    // Required
    age: string;
    isAuthRep: "true" | "false";
    preferredContactMethod: "email" | "sms";

    // Optional
    healthcare: string;
    livingSituation: string;
    monkeypoxExposure: string[];
    monkeypoxGroup: string[];
    monkeypoxSymptoms: string[];
    conditions: string[];

    // No Query
    firstName: string;
    lastName: string;
    zip: string;

    authRepFirstName: string
    authRepLastName: string
    authRepRelationship: string
    authRepPhone: string
    authRepEmail: string
    authRepDob: string

    /**
     * Format is YYYY-MM-DD
     */
    dob: string;

    phone: string;

    /**
     * Used for validating phone
     */
    otpId?: string;

    /**
     * Used for validating phone
     */
    otpCode?: string;


    captchaVerificationCode: string;

    // Indexed
    email: string;

    /**
     * Which lambda invocation request received the record
     */
    receivedRequestId?: string;

    /**
     * When record was marked exported in JS time
     */
    exported?: number;

    /**
     * Which lambda invocation request triggered the export
     */
    exportRequestId?: string;

    /**
     * Appended If automatic eligibility rule applied
     */
    rule?: EligibilityRule;

    /**
     * Confirmation that existing record should be overwritten
     */
    overwrite?: boolean;
}

const radioButtonValidation = (id: QuestionId): any => {
    const options = getQuestionForLanguage(id).options;
    return Yup.string().oneOf([""].concat(options.map(x => x.value)));
};
export function EligibilityFormDataSchema(lang: LanguageId) {

    return Yup.object({
        preferredLanguage: Yup.string().required(GetLabelForLanguage("requiredValidation", lang)).oneOf(["en", "es"]),
        jurisdiction: Yup.string().required(GetLabelForLanguage("requiredValidation", lang)).max(3),
        age: Yup.string().required(GetLabelForLanguage("requiredValidation", lang)).max(20),

        dob: Yup.string().matches(/(^$|^\d\d\d\d-\d\d-\d\d$)/, GetLabelForLanguage("dobFormat", lang)).required(),

        // Optional
        livingSituation: radioButtonValidation("livingSituation"),
        healthcare: radioButtonValidation("healthcare"),
        conditions: Yup.array().of(Yup.string().oneOf(getQuestionForLanguage("conditions").options.map(x => x.value))),

        // eslint-disable-next-line no-useless-escape
        firstName: Yup.string()
            .matches(/^[a-zA-Z0-9, .'\[\]-]+$/, GetLabelForLanguage("firstNameFormat", lang))
            .required(GetLabelForLanguage("firstNameRequired", lang))
            .max(40),
        // eslint-disable-next-line no-useless-escape
        lastName: Yup.string()
            .matches(/^[a-zA-Z0-9, .'\[\]-]+$/, GetLabelForLanguage("lastNameFormat", lang))
            .required(GetLabelForLanguage("lastNameRequired", lang))
            .max(80),

        email: Yup.string().email().max(80).when("preferredContactMethod", {
            is: "email",
            then: Yup.string().required(GetLabelForLanguage("emailRequired", lang))
        }),

        zip: Yup.string()
            .required(GetLabelForLanguage("zipRequired", lang))
            .matches(/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/, GetLabelForLanguage("zipFormat", lang)),

        phone: Yup.string().matches(/(^$|^[0-9]{10}$)/, GetLabelForLanguage("phoneFormat", lang)).when("preferredContactMethod", {
            is: "sms",
            then: Yup.string().required(GetLabelForLanguage("phoneRequired", lang))
        }),

        captchaVerificationCode: Yup.string(),

        isAuthRep: radioButtonValidation("isAuthRep"),
        authRepFirstName: Yup.string().when("isAuthRep", {
            is: "true",
            then: (schema) => schema.matches(/^[a-zA-Z0-9, .'\[\]-]+$/, GetLabelForLanguage("lastNameFormat", lang))
            .required(GetLabelForLanguage("lastNameRequired", lang))
            .max(80)
        }),
        authRepLastName: Yup.string().when("isAuthRep", {
            is: "true",
            then: (schema) => schema.matches(/^[a-zA-Z0-9, .'\[\]-]+$/, GetLabelForLanguage("lastNameFormat", lang))
            .required(GetLabelForLanguage("lastNameRequired", lang))
            .max(80)
        }),
        authRepEmail: Yup.string().when("isAuthRep", {
            is: "true",
            then: (schema) => schema.when('preferredContactMethod', {
                is: "email",
                then: (schema) => schema.email(GetLabelForLanguage("emailRequired", lang))
                    .required(GetLabelForLanguage("emailRequired", lang))
                    .max(80)
            })
        }),
        authRepPhone: Yup.string().when("isAuthRep", {
            is: "true",
            then: (schema) => schema.when('preferredContactMethod', {
                is: "sms",
                then: (schema) => schema.matches(/^[a-zA-Z0-9, .'\[\]-]+$/, GetLabelForLanguage("phoneRequired", lang))
                    .required(GetLabelForLanguage("phoneRequired", lang))
                    .max(80)
            })
        }),

        preferredContactMethod: radioButtonValidation("preferredContactMethod"),

        otpId: Yup.string().max(36),
        otpCode: Yup.string().max(6),

        overwrite: Yup.boolean(),
    });
}

export const defaultQueryParams = (jurisdiction: string = "", limit: number = undefined): QueryParams => {
    return {
        jurisdiction: jurisdiction,
        age: [],
        healthcare: [],
        livingSituation: [],
        // rural: [],
        // frontlineWorker: [],
        // essentialWorker: [],
        previewLimit: limit,
        exportLimit: limit,
        zip: "",
        monkeypoxExposure: [],
        monkeypoxGroup: [],
        monkeypoxSymptoms: [],
        conditions: [],
    };
};
export interface QueryParams {
    jurisdiction: string;
    age: string[];
    healthcare: string[];
    livingSituation: string[];
    // rural: string[];
    // frontlineWorker: string[];
    // essentialWorker: string[];
    previewLimit?: number;
    exportLimit?: number;

    monkeypoxExposure: string[];
    monkeypoxGroup: string[];
    monkeypoxSymptoms: string[];
    conditions: string[];

    /**
     * Zip Code Begins With
     */
    zip: string;
}
export const QueryParamsSchema = Yup.object({
    jurisdiction: Yup.string().required("Jurisdiction is required"),
    age: Yup.array().of(Yup.string()).min(1, "Please select at least 1 age group"),
    healthcare: Yup.array().of(Yup.string()),
    livingSituation: Yup.array().of(Yup.string()),
    rural: Yup.array().of(Yup.string()),
    frontlineWorker: Yup.array().of(Yup.string()),
    essentialWorker: Yup.array().of(Yup.string()),
    conditions: Yup.array().of(Yup.string()),
    zip: Yup.string().matches(/^(\d|,)*$/, "Only digits and commas are accepted"),
    previewLimit: Yup.number().positive().nullable(true).max(50000),
    exportLimit: Yup.number().positive().nullable(true).max(50000),
});
export interface EligibilityRule {
    jurisdiction: string;
    name: string;
    priority: number;

    // Start date inclusive as ISO
    startDate: string;

    // End date inclusive as ISO
    endDate: string;

    query: QueryParams;

    // Auto filled
    updatedBy?: string;
    updatedOn?: string;
}

export const EligibilityRuleSchema = Yup.object({
    jurisdiction: Yup.string().required("Jurisdiction is required"),
    name: Yup.string().required("Name is required").max(512),
    priority: Yup.number().required("Priority is required"),
    startDate: Yup.string().required("Start Date required").max(512),
    endDate: Yup.string().required("End Date required").max(512),
    query: QueryParamsSchema,
});

export interface GetStatusRequest {
    jurisdiction: string;
    pk: string;
    sk: string;
}
export const GetStatusRequestSchema = Yup.object({
    jurisdiction: Yup.string().required("jurisdiction is required"),
    pk: Yup.string().matches(/^export-status#\d\d\d\d#\d\d#\d\d$/, "Invalid PK"),
    sk: Yup.string().matches(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/, "Must be UUID"),
});

export interface IValidationErrors {
    [name: string]: string[];
}

export interface IValidationResult {
    isValid: boolean;
    errors: IValidationErrors;
}

export const validateSchema = (schema: any, data: object): IValidationResult => {
    const response: IValidationResult = {
        isValid: true,
        errors: {},
    };
    try {
        schema.validateSync(data, { abortEarly: false });
    } catch (error) {
        response.isValid = false;
        error.inner.forEach((e: any) => {
            response.errors[e.path] = e.errors;
        });
    }
    return response;
};

export type ExportStatusType = "Requesting" | "Searching" | "Exporting" | "Complete" | "Error";
export interface ExportStatus {
    /**
     * Date in Pacific Time
     */
    pk: string;
    /**
     * API GW Lambda Request Id
     */
    sk: string;
    /**
     * Current status
     */
    exportStatus: ExportStatusType;
    /**
     * Latest message
     */
    statusMessage: string;
}

/**
 * Gets current age for date of birth
 */
export const getAge = (dob: Date): number => {
    return moment().diff(dob, "years", false);
};

export const ageRanges = [
    [0, 17],
    [18, 24],
    [25, 29],
    [30, 34],
    [35, 39],
    [40, 44],
    [45, 49],
    [50, 54],
    [55, 59],
    [60, 64],
    [65, 74],
    [75, 84]
];

/**
 * Gets age range for given DOB. Returns fallback (form age range) if DOB is not valid.
 * Invalid DOB was coming from test data.
 */
export const getAgeRange = (dob: Date, fallback: string): string => {
    const age = getAge(dob);

    if (age <= 17) {
        return "12m-17";
    }

    for (const range of ageRanges) {
        if (range[0] <= age && age <= range[1]) {
            console.log(range)
            return `${range[0]}-${range[1]}`;
        }
    }

    if (age >= 85) {
        return "85+";
    }


    return fallback;
};

export interface SubmitFormResponse {
    confirmation: string;
    /**
     * ineligible - submitted, not currently eligible
     * eligible - eligible based on existing rules
     * exists - found match based on first/last/dob/zip. Resubmit with overwrite to update
     * exported - already exported so submit rejected
     */
    status: "ineligible" | "eligible" | "exists" | "exported",
}
